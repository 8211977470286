const actions = {
    GET_SECCIONES_REQUEST: 'GET_SECCIONES_REQUEST',
    GET_SECCIONES_SUCCESS: 'GET_SECCIONES_SUCCESS',
    CREATE_SECCION_REQUEST: 'CREATE_SECCION_REQUEST',
    CREATE_SECCION_SUCCESS: 'CREATE_SECCION_SUCCESS',
    DELETE_SECCION_REQUEST: 'DELETE_SECCION_REQUEST',
    DELETE_SECCION_SUCCESS: 'DELETE_SECCION_SUCCESS',
    UPLOAD_JSON: 'UPLOAD_JSON',
    GET_BANNERS_JSON_REQUEST: 'GET_BANNERS_JSON_REQUEST',
    GET_BANNERS_JSON_SUCCESS: 'GET_BANNERS_JSON_SUCCESS',
    GET_DESTACADOS_JSON_REQUEST: 'GET_DESTACADOS_JSON_REQUEST',
    GET_DESTACADOS_JSON_SUCCESS: 'GET_DESTACADOS_JSON_SUCCESS',
    getSecciones: () => ({ type: actions.GET_SECCIONES_REQUEST }),
    createSeccion: (data) => ({ type: actions.CREATE_SECCION_REQUEST, payload: data }),
    deleteSeccion: (id) => ({ type: actions.DELETE_SECCION_REQUEST, payload: id }),
    uploadJson: (data) => ({ type: actions.UPLOAD_JSON, payload: data }),
    getBannerJson: () => ({ type: actions.GET_BANNERS_JSON_REQUEST }),
    getDestacadosJson: () => ({ type: actions.GET_DESTACADOS_JSON_REQUEST }),
};
export default actions;
