import { all, takeEvery, put } from 'redux-saga/effects';
import actions from './actions';
import { notification } from '@iso/components';
const gaxios = require('gaxios');

export function* getSecciones() {

    try {
        const res = yield gaxios.request({
            url: 'https://e5c885q80e.execute-api.us-east-2.amazonaws.com/pruebas/config',
            method: 'GET',
        });
        if (!res.data) {
            notification('error', res.data.msg);
        } else {
            yield put({
                type: actions.GET_SECCIONES_SUCCESS,
                payload: res.data,
            });
        }
    } catch (error) {
        console.log(error);
    }
}

export function* createSeccion(action) {
    const res = yield gaxios.request({
        url: 'https://e5c885q80e.execute-api.us-east-2.amazonaws.com/pruebas/config',
        method: 'POST',
        data: action.payload,
    });
    if (res.status !== 200) {
        notification('error', 'Error al crear la sección');
    } else {
        notification('success', 'Sección creada con éxito');
        // yield put({ type: actions.GET_SECCIONES_REQUEST });
    }
}

export function* deleteSeccion(action) {
    const res = yield gaxios.request({
        url: `https://e5c885q80e.execute-api.us-east-2.amazonaws.com/pruebas/config/${action.payload}`,
        method: 'DELETE'
    });

    if (res.status !== 200) {
        notification('error', 'Error al eliminar la sección');
    } else {
        notification('success', 'Sección eliminada correctamente');
        // yield put({ type: actions.GET_SECCIONES_REQUEST });
    }
}

export function* uploadJson(action) {
    const res = yield gaxios.request({
        url: 'https://e5c885q80e.execute-api.us-east-2.amazonaws.com/pruebas/config/upload-json',
        method: 'POST',
        data: action.payload,
    });

    if (!res.data) {
        notification('error', 'Algo fallo');
    } else {
        notification('success', 'Se envio correctamente');
        if( action.payload.name === 'destacados.json' ){
            yield put({ type: actions.GET_DESTACADOS_JSON_REQUEST })
        }else{
            yield put({ type: actions.GET_BANNERS_JSON_REQUEST })
        }
        
    }
}
export function* getJsonBanner() {
    const res = yield gaxios.request({
        url: 'https://config-codice.s3.amazonaws.com/banners.json?cb=' + Date.now(),
        method: 'GET',
    });

    if (res.status !== 200) {
        notification('error', 'Error al cargar los banners');
    } else {
        yield put({ type: actions.GET_BANNERS_JSON_SUCCESS, payload: res.data.banners });
    }
}

export function* getJsonDestacados() {
    const res = yield gaxios.request({
        url: 'https://config-codice.s3.amazonaws.com/destacados.json?cb=' + Date.now() ,
        method: 'GET',
    });

    if (res.status !== 200) {
        notification('error', 'Error al cargar productos destacodos');
    } else {
        yield put({ type: actions.GET_DESTACADOS_JSON_SUCCESS, payload: res.data });
    }
}


export default function* rootSaga() {
    yield all([
        yield takeEvery(actions.GET_SECCIONES_REQUEST, getSecciones),
        yield takeEvery(actions.CREATE_SECCION_REQUEST, createSeccion),
        yield takeEvery(actions.DELETE_SECCION_REQUEST, deleteSeccion),
        yield takeEvery(actions.UPLOAD_JSON, uploadJson),
        yield takeEvery(actions.GET_BANNERS_JSON_REQUEST, getJsonBanner),
        yield takeEvery(actions.GET_DESTACADOS_JSON_REQUEST, getJsonDestacados),
    ]);
}
