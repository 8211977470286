const actions = {
    GET_PEDIDOS_REQUEST: 'GET_PEDIDOS_REQUEST',
    GET_PEDIDOS_SUCCESS: 'GET_PEDIDOS_SUCCESS',

    GET_PEDIDOS_BY_USER: 'GET_PEDIDOS_BY_USER',

    CREATE_PEDIDO_REQUEST: 'CREATE_PEDIDO_REQUEST',
    CREATE_PEDIDO_SUCCESS: 'CREATE_PEDIDO_SUCCESS',

    EDIT_PEDIDO_REQUEST: 'EDIT_PEDIDO_REQUEST',
    EDIT_PEDIDO_SUCCESS: 'EDIT_PEDIDO_SUCCESS',

    DELETE_PEDIDO_REQUEST: 'DELETE_PEDIDO_REQUEST',
    DELETE_PEDIDO_SUCCESS: 'DELETE_PEDIDO_SUCCESS',

    SET_CURRENT_PEDIDO: 'SET_CURRENT_PEDIDO',
    CLEAR_CURRENT_PEDIDO: 'CLEAR_CURRENT_PEDIDO',
    
    initDataPedidos: (query) => ({ type: actions.GET_PEDIDOS_REQUEST, query }),
    getPedidosByUser: (id) => ({ type: actions.GET_PEDIDOS_BY_USER, payload: id }),
    createPedido: data => ({
      type: actions.CREATE_PEDIDO_REQUEST,
      payload: data
    }),
    editPedido: (id, data) => ({
      type: actions.EDIT_PEDIDO_REQUEST,
      id,
      payload: data
    }),
    deletePedido: id => ({
      type: actions.DELETE_PEDIDO_REQUEST,
      payload: id
    }),
    setCurrentPedido: data => ({
      type: actions.SET_CURRENT_PEDIDO,
      payload: data
    }),
    clearCurrentPedido: () => ({
      type: actions.CLEAR_CURRENT_PEDIDO,
    }), 
  };
  export default actions;
  