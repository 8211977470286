import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import Mails from '@iso/redux/mail/reducer';
import Users from '@iso/redux/users/reducer';
import DynamicChartComponent from '@iso/redux/dynamicEchart/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import drawer from '@iso/redux/drawer/reducer';
import modal from '@iso/redux/modal/reducer';
import profile from '@iso/redux/profile/reducer';
import Denuncias from '@iso/redux/denuncias/reducers';
import Empresas from '@iso/redux/empresas/reducer';
import Productos from '@iso/redux/productos/reducer';
import Config from '@iso/redux/generalConfig/reducer';
import Migracion from '@iso/redux/migracion/reducer';
import Pedidos from '@iso/redux/pedidos/reducer';


export default combineReducers({
  Auth,
  App,
  Users,
  modal,
  drawer,
  profile,
  Denuncias,
  Empresas, 
  ThemeSwitcher,
  Mails,
  DynamicChartComponent,
  Productos,
  Config,
  Migracion,
  Pedidos
});
