const actions = {
    MIGRAR_EMPRESA_REQUEST: 'MIGRAR_EMPRESA_REQUEST',
    GET_EMPRESAS_REQUEST: 'GET_EMPRESA_REQUEST',
    GET_EMPRESAS_SUCCESS: 'GET_EMPRESA_SUCCESS',

    GET_EMPRESAS_PAGINADAS_REQUEST: 'GET_EMPRESAS_PAGINADAS_REQUEST',
    GET_EMPRESAS_PAGINADAS_SUCCESS: 'GET_EMPRESAS_PAGINADAS_SUCCESS',
    
    initEmpresasPaginadas: (query) => ({
      type: actions.GET_EMPRESAS_PAGINADAS_REQUEST,
      query
    }),
    getEmpresas: (query) => ({
      type: actions.GET_EMPRESAS_REQUEST,
      query
    }),
    migrarEmpresa: data => ({
      type: actions.MIGRAR_EMPRESA_REQUEST,
      payload: data
    }),
  };
  export default actions;
  