import clone from 'clone';
import actions from './actions';

const initState = {
  empresas: [],
  initialEmpresas: false,
  currentEmpresa: {},
  editableEmpresa: {},
  isNewEmpresa: true,
  enableEditView: false,
  isCreating:false,
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.UPDATE_EMPRESAS: {
      return {
        ...state,
        empresas: action.empresas,
        initialEmpresas: true,
        isCreating: false,
      };
    }
    case actions.UPDATE_EDIT_EMPRESA: {
      return {
        ...state,
        currentEmpresa: action.empresa,
        isNewEmpresa: false,
        enableEditView:true,
        isCreating: false,
      };
    }
    case actions.SELECT_CURRENT_EMPRESA: {
      return {
        ...state,
        currentEmpresa: action.empresa,
        isNewEmpresa:false,
        // isCreating: false,
        
      };
    }
    case actions.TOGGLE_VIEW:
      return {
        ...state,
        enableEditView: action.view,
        editableEmpresa: clone(state.currentEmpresa),
      };
    case actions.RESET_EMPRESA_EDIT:
      return{
        ...state,
        enableEditView: false,
        currentEmpresa:null
      }
    case actions.CREATE_EMPRESA:
      return {
        ...state,
        isCreating: true,
      };
    case actions.CREATE_EMPRESA_ERROR:
        return {
          ...state,
          isCreating: false,
        };
      case actions.DELETE_COMENTARIO:
        return{
          ...state,
          comentarios: action.comentarios,
        };
      case actions.COMENTARIOS_EMPRESA:
        return{
          ...state,
          comentarios: action.comentarios,
        }
    default:
      return state;
  }
}
