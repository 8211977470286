import { all, takeEvery, put, select } from 'redux-saga/effects';
import siteConfig from '@iso/config/site.config';
import actions from './actions';
import { notification } from '@iso/components';
const gaxios = require('gaxios');

export function* createCategoriaSaga(action) {
  let ULRDestino = siteConfig.siteUrl.API_URL + "api/admindata/categorias/";
  let metodo = "POST";
  const res = yield gaxios.request({
    url: ULRDestino,
    data: action,
    method: metodo,
  });
  if (res.data.creado) {
    yield put({
      type: actions.GET_CATEGORIAS
    });
  }
};

export function* deleteCategoriaSaga(action) {
  let ULRDestino = siteConfig.siteUrl.API_URL + "api/admindata/categorias/" + action.id;
  let metodo = "DELETE";
  const res = yield gaxios.request({
    url: ULRDestino,
    method: metodo,
  });
  if (res.data.eliminado) {
    yield put({
      type: actions.GET_CATEGORIAS
    });
  }
}

export function* getCategoriasSaga(action) {
  const res = yield gaxios.request({
    url: siteConfig.siteUrl.API_URL + 'api/admindata/categorias/',
  });
  yield put({
    type: actions.UPDATE_CATEGORIAS,
    categorias: res.data,
  });
}

export function* updateCategoriaSaga(action) {
  let ULRDestino = siteConfig.siteUrl.API_URL + "api/admindata/categorias/" + action.categoria.id;
  let metodo = "PUT";
  const data = action.categoria.icono
  const res = yield gaxios.request({
    url: ULRDestino,
    method: metodo,
    data: { icono: data },
  });
  if (res.data.actualizado) {
    yield put({
      type: actions.GET_CATEGORIAS
    });
  }
}

export function* updateCodigosCartaSaga(action) {
  let ULRDestino = siteConfig.siteUrl.API_URL + `api/admindata/cartaMenu`;
  let metodo = "POST";
  const res = yield gaxios.request({
    url: ULRDestino,
    method: metodo,
    data: action.data
  });
  if (res.data.success) {
    notification('success', res.data.msg)
  }
};

/*CODIGOS */
export function* createCodigosSaga(action) {
  // const { id_token } = yield select(state => state.Auth);
  let ULRDestino = 'https://e5c885q80e.execute-api.us-east-2.amazonaws.com/pruebas/codigos';
  let metodo = "POST";
  const res = yield gaxios.request({
    url: ULRDestino,
    method: metodo,
    data: action.transaccion,
    // headers: {
    //   "Codice-Auth": id_token,
    // }
  });
  if (res.status !== 200) {
    notification('error', 'Ocurrio un error al crear el código');
  } else {
    notification('success', 'Código creado correctamente');
    yield put({
      type: actions.GET_CODIGOS
    });
  }

};

export function* deleteCodigosSaga(action) {
  let ULRDestino = siteConfig.siteUrl.API_URL + "api/admindata/codigo/" + action.id;
  let metodo = "DELETE";
  const res = yield gaxios.request({
    url: ULRDestino,
    method: metodo,
  });
  if (res.data.success) {
    notification('success', res.data.msg)
    yield put({
      type: actions.GET_CODIGOS
    });
  }
}

export function* getCodigosSaga() {
  const res = yield gaxios.request({
    url: 'https://e5c885q80e.execute-api.us-east-2.amazonaws.com/pruebas/codigos',
  });
  yield put({
    type: actions.UPDATE_CODIGOS,
    codigos: res.data
  });
}

export function* updateCodigosSaga(action) {
  console.log(action)
  const res = yield gaxios.request({
    url: 'https://e5c885q80e.execute-api.us-east-2.amazonaws.com/pruebas/codigos/' + action.id,
    method: 'PUT',
    data: action.data,
  });
  if (res.status !== 200) {
    notification('error', 'Ocurrio un error al actualizar el código');
  } else {
    notification('success', 'Código actualizado correctamente');
    yield put({
      type: actions.GET_CODIGOS
    });
  }
}

export function* enviarCodigoSaga(action) {
  let ULRDestino = "https://e5c885q80e.execute-api.us-east-2.amazonaws.com/pruebas/codigos";
  let metodo = "PUT";
  const res = yield gaxios.request({
    url: ULRDestino,
    method: metodo,
    data: action.data,
  });
  if (res.data.success) {
    notification('success', res.data.msg);
    yield put({
      type: actions.GET_CODIGOS
    });
  }
}

export function* verificarCodigoSaga(action) {
  const { id : idUser } = yield select((state) => state.Auth);
  let ULRDestino = "https://e5c885q80e.execute-api.us-east-2.amazonaws.com/pruebas/codigos/" + action.codeChk + '/' + idUser;
  const res = yield gaxios.request({
    url: ULRDestino,
  });
  let id;
  let verificado;
  console.log(res);
  if (res.data.success) {
    id = res.data.codigo.id;
    verificado = true;
    notification('success', "Código verificado");
  } else {
    id = null;
    verificado = false;
    notification('error', res.data.msg);
  }
  const codigoVerificado = { codigo: id, verificado };
  yield put({
    type: actions.CODIGO_VERIFICADO,
    codigoVerificado,
  });
}

/*INFO BASE */
export function* getInfoBase(action) {
  const res = yield gaxios.request({
    url: 'api/admindata/',
  });
  yield put({
    type: actions.UPDATE_INFO_BASE,
    categorias: res.data.categorias,
    totalEmpresas: res.data.totalEmpresas,
  });
}

/*USER INFO ESCRITORIO */
export function* infoUserEscritorio(action) {
  const res = yield gaxios.request({
    url: siteConfig.siteUrl.API_URL + 'api/admindata/',
  });
  yield put({
    type: actions.UPDATE_INFO_BASE,
    categorias: res.data.categorias,
    totalEmpresas: res.data.totalEmpresas,
  });
}


/* EMAILS */
export function* sendEmail(action) {

  const res = yield gaxios.request({
    url: 'https://e5c885q80e.execute-api.us-east-2.amazonaws.com/pruebas/emails',
    method: 'POST',
    data: action.payload,
  });

  if (res.status !== 200) {
    notification('error', 'Ocurrio un error al enviar el correo');
  } else {
    notification('success', 'Correo enviado con éxito');
  }

};


export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.GET_INFO_BASE, getInfoBase),
    /*CATEGORIAS */
    yield takeEvery(actions.GET_CATEGORIAS, getCategoriasSaga),
    yield takeEvery(actions.CREATE_CATEGORIAS, createCategoriaSaga),
    yield takeEvery(actions.DELETE_CATEGORIAS, deleteCategoriaSaga),
    yield takeEvery(actions.UPDATE_CATEGORIA, updateCategoriaSaga),
    yield takeEvery(actions.ENVIAR_CODIGOS_CARTA, updateCodigosCartaSaga),

    /*CODIGOS */
    yield takeEvery(actions.GET_CODIGOS, getCodigosSaga),
    yield takeEvery(actions.CREATE_CODIGOS, createCodigosSaga),
    yield takeEvery(actions.DELETE_CODIGOS, deleteCodigosSaga),
    yield takeEvery(actions.UPDATE_CODIGO, updateCodigosSaga),
    yield takeEvery(actions.CHECK_CODIGO, verificarCodigoSaga),
    yield takeEvery(actions.ENVIAR_CODIGOS, enviarCodigoSaga),

    /*USER INFO */
    yield takeEvery(actions.GET_USER_INFO_DASHBOARD, infoUserEscritorio),
    
    /* EMAILS */
    yield takeEvery(actions.SEND_EMAIL, sendEmail),

  ]);
}
