import { all, takeEvery, put } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import { notification } from "@iso/components";
import actions from "./actions";
import siteConfig from "@iso/config/site.config";
import profileActions from "@iso/redux/profile/actions";
const gaxios = require("gaxios");
const history = createBrowserHistory();

export function* loginRequest(action) {
  let ULRDestino = siteConfig.siteUrl.API_URL + "api/auth/";
  let metodo = "POST";
  const res = yield gaxios.request({
    url: ULRDestino,
    method: metodo,
    data: action.payload,
  });
  if (res.data.success) {
    yield put({
      type: actions.LOGIN_SUCCESS,
      access_token: res.data.token,
      id_token: res.data.user.id,
      cdt: res.data.user.cdt,
      nombre: res.data.name,
      email: res.data.email,
      avatar: res.data.avatar,
    });
  } else {
    yield put({
      type: actions.LOGIN_ERROR,
      msg: res.data.msg,
    });
  }
};

export function* finishCognitoLogin(action) {
  if (action.payload) {
    const accessToken = action.payload.accessToken.jwtToken;
    const idToken = action.payload.idToken.jwtToken;
    const data = action.payload.idToken.payload;
    yield put({
      type: actions.LOGIN_SUCCESS,
      access_token: accessToken,
      id_token: idToken,
      email: data.email,
      cdt: 0,
      id: data.sub
    });

    yield put({
      type: profileActions.GET_CURRENT_PROFILE_REQUEST,
      payload: data.sub,
    });

  }
};

export function* recoveryRequest(action) {
  let ULRDestino = siteConfig.siteUrl.API_URL + "api/auth/recovery/";
  let metodo = "POST";
  const res = yield gaxios.request({
    url: ULRDestino,
    method: metodo,
    data: { email: action.payload },
  });
  if (res.data.success) {
    yield clearToken();
    notification('success', res.data.msg);
    history.push('/');
  } else {
    yield put({
      type: actions.LOGIN_ERROR,
      msg: res.data.msg,
    });
  }
};

export function* setRecovery(action) {
  let ULRDestino = siteConfig.siteUrl.API_URL + "api/auth/reset/";
  let metodo = "POST";
  const res = yield gaxios.request({
    url: ULRDestino,
    method: metodo,
    data: action.payload,
  });
  if (res.data.success) {
    notification('success', res.data.msg);
    yield put({
      type: actions.LOGIN_REQUEST,
      payload: { email: res.data.user.email, pass: action.payload.pass }
    });
  } else {
    notification('error', res.data.msg);
  }
};

export function* loginSuccess(action) {
  yield localStorage.setItem("access_token", action.access_token);
  yield localStorage.setItem("id_token", action.id_token);
};

export function* logout() {
  yield put({
    type: actions.LOGOUT_SUCCESS
  });
  history.push('/signin');
};

export function* checkAuthorization() {
  const idUser = localStorage.getItem('id_token');
  const token = localStorage.getItem('access_token');
  let ULRDestino = siteConfig.siteUrl.API_URL + "api/auth/chk/" + idUser;
  let metodo = "POST";
  if (token && idUser) {
    const res = yield gaxios.request({
      url: ULRDestino,
      method: metodo,
      headers: {
        Authorization: token,
      },
    });
    if (res.data.success) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        id_token: idUser,
        access_token: token,
        nombre: res.data.user.name,
        email: res.data.user.email,
        cdt: res.data.user.cdt,
        avatar: res.data.user.avatar
          ? `${siteConfig.siteUrl.IMG_PATH}/${res.data.user.avatar}`
          : null,
      });
    } else {
      yield put({
        type: actions.LOGIN_ERROR,
        msg: res.data.msg,
      });
    }
  }
};

export function* signupSaga(action) {
  let ULRDestino = siteConfig.siteUrl.API_URL + "api/registro/";
  let metodo = "POST";
  const res = yield gaxios.request({
    url: ULRDestino,
    method: metodo,
    data: action.payload,
  });
  if (res.data.success) {
    yield put({
      type: actions.LOGIN_SUCCESS,
      access_token: res.data.token,
      id_token: res.data.user.id,
      cdt: res.data.user.cdt,
      nombre: res.data.name,
    });
  } else {
    yield put({
      type: actions.LOGIN_ERROR,
      msg: res.data.msg,
    });
  }
};

function clearToken() {
  localStorage.removeItem('access_token');
  localStorage.removeItem('id_token');
};

export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.LOGIN_SUCCESS, loginSuccess),
    yield takeEvery(actions.LOGIN_REQUEST, finishCognitoLogin),
    yield takeEvery(actions.LOGOUT, logout),
    yield takeEvery(actions.CHECK_AUTHORIZATION, checkAuthorization),
    yield takeEvery(actions.LOGIN_SIGNUP, signupSaga),
    yield takeEvery(actions.PASS_RECOVERY, recoveryRequest),
    yield takeEvery(actions.SET_RECOVERY, setRecovery),
  ]);
}
