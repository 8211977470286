import { all, takeEvery, put, select } from "redux-saga/effects";
import actions from "./actions";
import { notification } from "@iso/components";
import profileActions from "../profile/actions";
import migracionActions from "../migracion/actions";
import usersActions from "../users/actions";
const gaxios = require("gaxios");

export function* getEmpresas(action) {
  const res = yield gaxios.request({
    url: "http://3.138.50.118:4000/api/empresas",
  });
  yield put({
    type: actions.UPDATE_EMPRESAS,
    empresas: res.data,
  });
}

export function* getEmpresasUsuario(action) {
  const res = yield gaxios.request({
    url: "api/empresas/user/" + action.id,
    headers: {
      Authorization: localStorage.getItem("access_token"),
      AuthId: localStorage.getItem("id_token"),
    },
  });
  if (res.data.success) {
    if (action.usuarios) {
      yield put({
        type: usersActions.CUERRENT_USER,
        currentUserEmpresas: res.data.empresas,
      });
    } else {
      yield put({
        type: profileActions.PROFILE_EMPRESAS,
        empresas: res.data.empresas,
      });
    }
  }
}

export function* getEmpresaEdit(action) {
  const res = yield gaxios.request({
    url: `https://e5c885q80e.execute-api.us-east-2.amazonaws.com/pruebas/empresas/${action.id}`,
    method: "GET",
  });
  console.log(res);
  if (res.data) {
    yield put({
      type: actions.UPDATE_EDIT_EMPRESA,
      empresa: res.data,
    });
  }
}

export function* updateEmpresaSaga({ empresas, empresa }) {
  const res = yield gaxios.request({
    url: "api/empresas",
  });
  yield put({
    type: actions.UPDATE_EMPRESA,
    res,
    empresa,
  });
}

export function* createEmpresaSaga(empresa) {
  let ULRDestino = "api/empresas";
  let metodo = "POST";
  if (empresa.accion) {
    ULRDestino = `api/empresas/${empresa.data.id}`;
    metodo = "PUT";
  }
  yield put({
    type: actions.CREATE_EMPRESA,
  });
  const res = yield gaxios.request({
    url: ULRDestino,
    data: empresa.data,
    method: metodo,
  });
  if (res.data.success) {
    console.log(res.data);
    notification("success", res.data.msg);
    yield put({
      type: actions.GET_EMPRESAS,
    });
    yield put({
      type: actions.SELECT_CURRENT_EMPRESA,
      empresa: res.data.empresa,
    });
  } else {
    yield put({
      type: actions.CREATE_EMPRESA_ERROR,
    });
    notification("error", res.data.msg);
  }
}
export function* createEmpresaAWS(empresa) {
  const res = yield gaxios.request({
    url: "https://e5c885q80e.execute-api.us-east-2.amazonaws.com/pruebas/empresas",
    method: "POST",
    data: empresa.data,
  });
  if (res.status !== 200) {
    notification("error", "Sin Informacion");
  } else {
    notification("success", "Empresa creada correctamente");
    yield put({
      type: migracionActions.GET_EMPRESAS_REQUEST,
    });
    yield put({
      type: actions.CREATE_EMPRESA,
    });
  }
}

export function* editEmpresaAWS(action) {
  delete action.data.id;
  const res = yield gaxios.request({
    url: `https://e5c885q80e.execute-api.us-east-2.amazonaws.com/pruebas/empresas/${action.id}`,
    method: "PUT",
    data: action.data,
  });
  if (res.status !== 200) {
    notification("error", "Sin Informacion");
  } else {
    notification("success", "Empresa editada correctamente");

    if (action.domiciliario) {
      yield put({
        type: migracionActions.GET_EMPRESAS_REQUEST,
        query: 'domiciliario=true&todas=true'
      });
    } else {
      yield put({
        type: migracionActions.GET_EMPRESAS_REQUEST,
        query: 'domiciliario=false&todas=true'
      });
    }
  }
}

export function* getEmpresasByUserAWS(action) {
  const res = yield gaxios.request({
    url: `https://e5c885q80e.execute-api.us-east-2.amazonaws.com/pruebas/empresas?userId=${action.id}`,
    method: "GET",
  });
  if (res.data) {
    if (res.data[0]) {
      yield put({
        type: actions.SELECT_CURRENT_EMPRESA,
        empresa: res.data[0],
      });
    }
  } else {
    yield put({
      type: actions.CREATE_EMPRESA_ERROR,
    });
    notification("error", "Error al obtener la empresa");
  }
}
export function* deleteEmpresaAWS(action) {
  const res = yield gaxios.request({
    url: `https://e5c885q80e.execute-api.us-east-2.amazonaws.com/pruebas/empresas/${action.id}`,
    method: "DELETE",
  });
  if (res.status !== 200) {
    notification("error", "Sin Informacion");
  } else {
    notification("success", "Empresa eliminada correctamente");
    yield put({
      type: migracionActions.GET_EMPRESAS_REQUEST,
    });
  }
}

export function* getComentarios(action) {
  const res = yield gaxios.request({
    url: `api/comentarios/empresa/${action.id}`,
    headers: {
      Authorization: localStorage.getItem("access_token"),
      AuthId: localStorage.getItem("id_token"),
    },
  });
  if (res.data.success) {
    yield put({
      type: actions.COMENTARIOS_EMPRESA,
      comentarios: res.data.comentarios,
    });
  }
}

export function* eliminarComentario(action) {
  let ULRDestino = "api/comentarios/" + action.id;
  let metodo = "DELETE";
  const res = yield gaxios.request({
    url: ULRDestino,
    method: metodo,
  });
  if (res.data.success) {
    console.log(res.data);
    notification("success", res.data.msg);
    yield put({
      type: actions.GET_COMENTARIOS,
      id: action.current,
    });
  } else {
    notification("error", res.data.msg);
  }
}
export function* editEmpresaSaga(empresa, _id) {
  const ULRDestino = `api/empresas/${empresa._id}`;
  const metodo = "PUT";
  yield put({
    type: actions.CREATE_EMPRESA,
  });
  const res = yield gaxios.request({
    url: ULRDestino,
    data: empresa.data,
    method: metodo,
  });
  if (res.data.success) {
    notification("success", res.data.msg);
    yield put({
      type: actions.GET_EMPRESAS,
    });
  } else {
    yield put({
      type: actions.CREATE_EMPRESA_ERROR,
    });
    notification("error", res.data.msg);
  }
}

export function* eliminarEmpresa(action) {
  let ULRDestino = "api/empresas/" + action.id;
  let metodo = "DELETE";
  const res = yield gaxios.request({
    url: ULRDestino,
    method: metodo,
  });
  if (res.data.success) {
    yield put({
      type: actions.GET_EMPRESAS,
    });
    notification("success", res.data.msg);
  } else {
    notification("error", res.data.msg);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.GET_EMPRESAS, getEmpresas),
    yield takeEvery(actions.GET_EMPRESAS_USER, getEmpresasUsuario),
    yield takeEvery(actions.GET_EMPRESA_EDIT, getEmpresaEdit),
    yield takeEvery(actions.UPDATE_EMPRESA_SAGA, updateEmpresaSaga),
    yield takeEvery(actions.EDIT_EMPRESA_SAGA, editEmpresaAWS),
    yield takeEvery(actions.CREATE_EMPRESA_SAGA, createEmpresaAWS),
    yield takeEvery(actions.DELETE_EMPRESA, deleteEmpresaAWS),
    yield takeEvery(actions.DELETE_COMENTARIO, eliminarComentario),
    yield takeEvery(actions.GET_COMENTARIOS, getComentarios),
    yield takeEvery(actions.GET_EMPRESA_BY_USER_ID, getEmpresasByUserAWS),
  ]);
}
