import { all, takeEvery, put } from "redux-saga/effects";
import actions from "./actions";
import { notification } from "@iso/components";
const gaxios = require("gaxios");

export function* getPedidos(action) {
  const query = action.query ? `?${action.query}` : "";
  try {
    const res = yield gaxios.request({
      url:
        "https://e5c885q80e.execute-api.us-east-2.amazonaws.com/pruebas/pedidos" +
        query,
    });

    if (res.status !== 200) {
      notification("error", 'Ocurrio un error al obtener los pedidos');
    } else {
      yield put({
        type: actions.GET_PEDIDOS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
}
export function* getPedidosByUsuario(action) {
  try {
    const res = yield gaxios.request({
      url:
        `https://e5c885q80e.execute-api.us-east-2.amazonaws.com/pruebas/pedidos/usuarios/${action.payload}`
    });

    if (res.status !== 200) {
      notification("error", 'Ocurrio un error al obtener los pedidos');
    } else {
      yield put({
        type: actions.GET_PEDIDOS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* createPedido(action) {
  const res = yield gaxios.request({
    url: "https://e5c885q80e.execute-api.us-east-2.amazonaws.com/pruebas/pedidos",
    method: "POST",
    data: action.payload,
  });
  
  if (res.status !== 200) {
    notification('error', 'Sin Informacion');
  } else {
    notification('success', 'Pedido Creado Correctamente');
    yield put({ type: actions.GET_PEDIDOS_REQUEST });
  }
}

export function* editPedido(action) {
  const res = yield gaxios.request({
    url: `https://e5c885q80e.execute-api.us-east-2.amazonaws.com/pruebas/pedidos/${action.id}`,
    method: "PUT",
    data: action.payload,
  });
  console.log(res);
  if (res.status !== 200) {
    notification('error', 'Sin Informacion');
  } else {
    notification('success', 'Pedido Actualizado Correctamente');
    yield put({ type: actions.GET_PEDIDOS_REQUEST });
  }
}

export function* deletePedido(action) {
  const res = yield gaxios.request({
    url: `https://e5c885q80e.execute-api.us-east-2.amazonaws.com/pruebas/pedidos/${action.payload}`,
    method: "DELETE",
  });

  if (res.status !== 200) {
    notification('error', 'Sin Informacion');
  } else {
    notification('success', 'Pedido Eliminado Correctamente');
    yield put({ type: actions.GET_PEDIDOS_REQUEST });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.GET_PEDIDOS_REQUEST, getPedidos),
    yield takeEvery(actions.GET_PEDIDOS_BY_USER, getPedidosByUsuario),
    yield takeEvery(actions.CREATE_PEDIDO_REQUEST, createPedido),
    yield takeEvery(actions.EDIT_PEDIDO_REQUEST, editPedido),
    yield takeEvery(actions.DELETE_PEDIDO_REQUEST, deletePedido),
  ]);
}
