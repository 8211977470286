const prod = {
  url: {
    API_URL: 'http://3.138.50.118:4000/',
    IMG_PATH: 'https://codice-assets.s3.us-east-2.amazonaws.com',
    FRONT_URL: 'http://codiceweb.s3-website.us-east-2.amazonaws.com/',
    WEB: 'https://codice.com.co/',
    IMG_PATH: 'https://codice-assets.s3.us-east-2.amazonaws.com',
  },
  idRestaurantes: '5fbffe917c7b691dd0e9c4ee',
};


const dev = {
  url: {
    //API_URL: 'https://codice.com.co:4000/',  
    API_URL: localStorage.getItem('APP_MODE')
      ? localStorage.getItem('APP_MODE') === 'production'
        ? 'http://3.138.50.118:4000/'
        : 'http://localhost:4000/'
      : 'http://3.138.50.118:4000/',
    FRONT_URL: 'http://localhost:3001/#/',
    IMG_PATH: 'https://codice-assets.s3.us-east-2.amazonaws.com',
    WEB: 'https://codice.com.co',
    IMG_PATH: 'https://codice-assets.s3.us-east-2.amazonaws.com',
  },
  idRestaurantes: '5fbf18b9ba3c0e146853bb90'
};

export default {
  siteName: 'CÓDICE',
  siteIcon: 'logo.svg',
  footerText: `CÓDICE SAS @ ${new Date().getFullYear()}`,
  enableAnimatedRoute: false,
  siteUrl: process.env.NODE_ENV === 'development' ? dev.url : prod.url,
  idRestaurantes: process.env.NODE_ENV === 'development' ? dev.idRestaurantes : prod.idRestaurantes,
  google: {
    analyticsKey: 'UA-xxxxxxxxx-1',
  },
  dashboard: '/dashboard',
};
