const usersActions = {
  ADD_USERS: 'ADD_USERS',
  EDIT_USER: 'EDIT_USER',
  GET_USERS: 'GET_USERS',
  DELETE_USER: 'DELETE_USER',
  CHANGE_USER: 'CHANGE_USER',
  CUERRENT_USER: 'CUERRENT_USER',
  EDIT_VIEW: 'EDIT_VIEW',
  IMPORT_USERS: 'IMPORT_USERS',
  changeUser: id => ({
    type: usersActions.CHANGE_USER,
    id,
  }),
  editUser: (id, payload) => {
    return {
      type: usersActions.EDIT_USER,
      id,
      payload
    }
  },
  deleteUser: id => ({
    type: usersActions.DELETE_USER,
    id
  }),
  getUsers: () => ({
    type: usersActions.GET_USERS,
  }),
  importUsers: data => ({
    type: usersActions.IMPORT_USERS,
    data
  }),
  viewChange: view => ({
    type: usersActions.EDIT_VIEW,
    view,
  }),
};
export default usersActions;
