import actions from './actions';

const initialState = {
    secciones: [],
    banners: [],
    productosDestacados: [],
};

export default function configReducer(state = initialState, action) {
    switch (action.type) {
        case actions.GET_SECCIONES_SUCCESS:
            return {
                ...state,
                secciones: action.payload,
            }
        case actions.GET_BANNERS_JSON_SUCCESS:
            return {
                ...state,
                banners: action.payload,
            }
        case actions.GET_DESTACADOS_JSON_SUCCESS:
            return {
                ...state,
                productosDestacados: action.payload,
            }
        default:
            return state;
    }

}
