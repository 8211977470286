import { store } from "./store";
import authActions from "@iso/redux/auth/actions";
import { CognitoUserPool } from "amazon-cognito-identity-js";

export default () => {
  const poolData = {
    UserPoolId: "us-east-2_avjHJQqqr",
    ClientId: "2unb7jr4narho8cuel36sfo25j",
  };
  const userPool = new CognitoUserPool(poolData);
  const cognitoUser = userPool.getCurrentUser();

  if (cognitoUser !== null) {
    cognitoUser.getSession((err, session) => {
      if (session) {
        if (session.isValid()) {
          return new Promise(() => {
            store.dispatch(authActions.login(session));
          });
        }
      } else {
        return new Promise(() => {
          store.dispatch(authActions.logout());
        });
      }
    });
  }
  return new Promise(() => {
    
  });
};
