import { all, takeEvery, put, select } from 'redux-saga/effects';
import actions from './actions';
import { notification } from '@iso/components';
const gaxios = require('gaxios');

export function* getProductos(action) {
    const query = action.query ? `?${action.query}` : '';
    try {
        const res = yield gaxios.request({
            url: 'https://e5c885q80e.execute-api.us-east-2.amazonaws.com/pruebas/productos/paginados' + query,
        });

        if (!res.data) {
            notification('error', res.data.msg);
        } else {
            yield put({
                type: actions.GET_PRODUCTOS_SUCCESS,
                payload: res.data.Items,
            });
        }
    } catch (error) {
        console.log(error);
    }
};

export function* getProductosById(action) {
    try {
        const res = yield gaxios.request({
            url: `https://e5c885q80e.execute-api.us-east-2.amazonaws.com/pruebas/perfil/producto/${action.id}`,
        });

        if (!res.data) {
            notification('error', res.data.msg);
        } else {
            yield put({
                type: actions.GET_PRODUCTOS_BY_ID_SUCCESS,
                payload: res.data,
            });
        }
    } catch (error) {
        console.log(error);
    }
};

export function* createProducto(action) {
    const { currentEmpresa } = yield select(state => state.Empresas);
    const { cdt } = yield select(state => state.Auth);
    const res = yield gaxios.request({
        url: 'https://e5c885q80e.execute-api.us-east-2.amazonaws.com/pruebas/perfil/producto',
        method: 'POST',
        data: action.payload,
    });

    if (!res.data) {
        notification('error', 'Error al crear el producto');
    } else {
        notification('success', 'Producto creado correctamente');
        console.log(currentEmpresa);
        if (currentEmpresa && cdt < 4) {
            yield put({ type: actions.GET_PRODUCTOS_REQUEST, query: `&idEmpresa=${currentEmpresa.id}` });
        } else {
            yield put({ type: actions.GET_PRODUCTOS_REQUEST });
        }
    }
};

export function* editProducto(action) {
    const { currentEmpresa } = yield select(state => state.Empresas);
    const { cdt } = yield select(state => state.Auth);
    const res = yield gaxios.request({
        url: `https://e5c885q80e.execute-api.us-east-2.amazonaws.com/pruebas/perfil/producto/${action.id}`,
        method: 'PUT',
        data: action.payload,
    });
    if (res.status !== 200) {
        notification('error', 'Ocurrio un error al editar el producto');
    } else {
        notification('success', 'Producto actualizado correctamente');
        if (currentEmpresa && cdt < 4) {
            yield put({ type: actions.GET_PRODUCTOS_REQUEST, query: `&idEmpresa=${currentEmpresa.id}` });
        } else {
            yield put({ type: actions.GET_PRODUCTOS_REQUEST });
        }
    }
};

export function* deleteProducto(action) {
    const { currentEmpresa } = yield select(state => state.Empresas);
    // const { id_token } = yield select(state => state.Auth);
    const res = yield gaxios.request({
        url: `https://e5c885q80e.execute-api.us-east-2.amazonaws.com/pruebas/perfil/producto/${action.payload}`,
        method: 'DELETE',
        // headers: {
        //     "Codice-Auth": id_token,
        // }
    });
    if (res.status !== 200) {
        notification('error', 'Sin Informacion');
    } else {
        notification('success', 'Producto eliminado correctamente');
        if (currentEmpresa) {
            yield put({ type: actions.GET_PRODUCTOS_REQUEST, query: `&idEmpresa=${currentEmpresa.id}` });
        } else {
            yield put({ type: actions.GET_PRODUCTOS_REQUEST });
        }
    }
};

export default function* rootSaga() {
    yield all([
        yield takeEvery(actions.GET_PRODUCTOS_REQUEST, getProductos),
        yield takeEvery(actions.GET_PRODUCTOS_BY_ID_REQUEST, getProductosById),
        yield takeEvery(actions.CREATE_PRODUCTO_REQUEST, createProducto),
        yield takeEvery(actions.EDIT_PRODUCTO_REQUEST, editProducto),
        yield takeEvery(actions.DELETE_PRODUCTO_REQUEST, deleteProducto),
    ]);
}
