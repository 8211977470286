const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_SIGNUP: 'LOGIN_SIGNUP',
  PASS_RECOVERY: 'PASS_RECOVERY',
  SET_RECOVERY: 'SET_RECOVERY',
  UPDATE_LOGIN_INFO: 'UPDATE_LOGIN_INFO',
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: (data) => ({
    type: actions.LOGIN_REQUEST,
    payload: data,
  }),
  logout: () => ({
    type: actions.LOGOUT,
  }),
  signup: (data) => ({
    type: actions.LOGIN_SIGNUP,
    payload: data,
  }),
  passRecovery: (data) => ({
    type: actions.PASS_RECOVERY,
    payload: data,
  }),
  setRecovery: (data) => ({
    type: actions.SET_RECOVERY,
    payload: data,
  }),
};
export default actions;
