import usersActions from './actions';

const users = [];

const initState = {
  users,
  selectedId: [],
  editView: false,
  loadedUsers:false,
  currentUserEmpresas:[]
};

export default function usersReducer(state = initState, action) {
  switch (action.type) {
    case usersActions.CHANGE_USER:
      return {
        ...state,
        selectedId: action.id,
        editView: false,
      };
    case usersActions.ADD_USERS:
      return {
        ...state,
        users: action.users,
        loadedUsers:true
      };
    case usersActions.EDIT_VIEW:
      return {
        ...state,
        editView: action.view,
      };
    case usersActions.CUERRENT_USER:
      return {
        ...state,
        currentUserEmpresas: action.currentUserEmpresas
      }
    default:
      return state;
  }
}

export { users };
