import { all, takeEvery, put } from 'redux-saga/effects';
import actions from './actions';
import { notification } from '@iso/components';
const gaxios = require('gaxios');

export function* migrarEmpresa(action) {
    const res = yield gaxios.request({
        url: 'https://e5c885q80e.execute-api.us-east-2.amazonaws.com/pruebas/migracion',
        method: 'POST',
        data: action.payload,
    });

    if (!res.data) {
        notification('error', res.data.msg);
    } else {
        notification('success', res.data.msg);
        yield put({ type: actions.GET_EMPRESAS_REQUEST });
    }
}
export function* getEmpresas(action) {
    const query = action.query ? `?${action.query}` : '';
    const res = yield gaxios.request({
        url: 'https://e5c885q80e.execute-api.us-east-2.amazonaws.com/pruebas/empresas' + query,
        method: 'GET',
    });

    if (!res.data) {
        notification('error', res.data.msg);
    } else {
        yield put({ type: actions.GET_EMPRESAS_SUCCESS, payload: res.data });
    }
}

export function* getEmpresasPaginadas(action) {
    const query = action.query ? `?${action.query}` : '';
    const res = yield gaxios.request({
        url: 'https://e5c885q80e.execute-api.us-east-2.amazonaws.com/pruebas/empresas/paginadas' + query,
        method: 'GET',
    });

    if (!res.data) {
        notification('error', res.data.msg);
    } else {
        yield put({ type: actions.GET_EMPRESAS_PAGINADAS_SUCCESS, payload: res.data.Items });
    }
}

export default function* rootSaga() {
    yield all([
        yield takeEvery(actions.MIGRAR_EMPRESA_REQUEST, migrarEmpresa),
        yield takeEvery(actions.GET_EMPRESAS_REQUEST, getEmpresas),
        yield takeEvery(actions.GET_EMPRESAS_PAGINADAS_REQUEST, getEmpresasPaginadas),
    ]);
}
