const actions = {
  GET_DENUNCIAS: 'GET_DENUNCIAS',
  GET_DENUNCIA: 'GET_DENUNCIA',
  DELETE_DENUNCIA: 'DELETE_DENUNCIA',
  FORMAT_DENUNCIAS: 'FORMAT_DENUNCIAS',
  EDIT_DENUNCIA: 'DELETE_DENUNCIA',
  getDenuncias: (query) => ({
    type: actions.GET_DENUNCIAS,
    query
  }),
  getDenuncia: (id) => ({
    type: actions.GET_DENUNCIA,
    id,
  }),
  deletDenuncia: (id) => ({
    type: actions.DELETE_DENUNCIA,
    id
  }),
  editDenuncia: data => ({
    type: actions.EDIT_DENUNCIA,
    data
  }),
};
export default actions;
