import actions from './actions';

const initialState = {
    productos: [],
    currentProducto: null,
};

export default function productoReducer(state = initialState, action) {
    switch (action.type) {
        case actions.GET_PRODUCTOS_SUCCESS:
            return {
                ...state,
                productos: action.payload,
            }
        case actions.GET_PRODUCTOS_BY_ID_SUCCESS:
            return {
                ...state,
                productos: action.payload,
            }
        case actions.DELETE_PRODUCTO_SUCCESS:
            return {
                ...state,
                productos: state.productos.filter(producto => producto._id !== action.payload)
            }
        case actions.SET_CURRENT_PRODUCTO:
            return {
                ...state,
                currentProducto: action.payload
            }
        case actions.CLEAR_CURRENT_PRODUCTO:
            return {
                ...state,
                currentProducto: null
            }
        default:
            return state;
    }

}
