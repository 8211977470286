const actions = {
  GET_EMPRESA: 'GET_EMPRESA',
  GET_EMPRESAS: 'GET_EMPRESAS',
  GET_EMPRESAS_USER: 'GET_EMPRESAS_USER',
  GET_EMPRESA_EDIT: 'GET_EMPRESA_EDIT',
  DELETE_EMPRESA: 'DELETE_EMPRESA',
  UPDATE_EMPRESA: 'UPDATE_EMPRESA',
  UPDATE_EMPRESAS: 'UPDATE_EMPRESAS',
  CREATE_EMPRESA: 'CREATE_EMPRESA',
  CREATE_EMPRESA_ERROR: 'CREATE_EMPRESA_ERROR',
  UPDATE_EMPRESA_SAGA: 'UPDATE_EMPRESA_SAGA',
  CREATE_EMPRESA_SAGA: 'CREATE_EMPRESA_SAGA',
  EDIT_EMPRESA_SAGA: 'EDIT_EMPRESA_SAGA',
  SELECT_CURRENT_EMPRESA: 'SELECT_CURRENT_EMPRESA',
  TOGGLE_VIEW: 'EMPRESA_TOGGLE_VIEW',
  UPDATE_EDIT_EMPRESA: 'EMPRESA_UPDATE_EDIT_EMPRESA',
  RESET_EMPRESA_EDIT: 'RESET_EMPRESA_EDIT',
  DELETE_COMENTARIO: 'DELETE_COMENTARIO',
  GET_COMENTARIOS:'GET_COMENTARIOS',
  COMENTARIOS_EMPRESA: 'COMENTARIOS_EMPRESA',
  GET_EMPRESA_BY_USER_ID: 'GET_EMPRESA_BY_USER_ID',
  initData: () => ({ type: actions.GET_EMPRESAS }),
  getComentarios: ( action ) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.GET_COMENTARIOS,
        id: action,
      });
    };
  },
  deleteComentario: ( id,current ) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.DELETE_COMENTARIO,
        id: id,
        current:current
      });
    };
  },
  getEmpresasUser: (id, usuarios) => {
    return { type: actions.GET_EMPRESAS_USER, id, usuarios }
  },
  resetEmpresaEdit: () => ({type: actions.RESET_EMPRESA_EDIT}),
  initEmpresaEdit: (action) => ({ type: actions.GET_EMPRESA_EDIT, id: action }),
  deleteEmpresa: (action) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.DELETE_EMPRESA,
        id: action,
      });
    };
  },
  updateEmpresa: empresa => {
    return (dispatch, getState) => {
      const empresas = getState().Empresas.empresa;
      const index = empresa.map(emp => emp.id).indexOf(empresa.id);
      if (index === -1) {
        empresas.push(empresa);
      } else {
        empresas[index] = empresa;
      }
      dispatch({
        type: actions.UPDATE_EMPRESA_SAGA,
        empresas,
        empresa,
      });
    };
  },
  crearEmpresa: (empresa, accion) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.CREATE_EMPRESA_SAGA,
        data:empresa,
        accion: accion,
      });
    };
  },
  editarEmpresa: (empresa, id, domiciliario) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.EDIT_EMPRESA_SAGA,
        data:empresa,
        id,
        domiciliario,
      });
    };
  },
  selectCurrentEmpresa: id => ({ type: actions.SELECT_CURRENT_EMPRESA, id }),
  toggleView: view => ({ type: actions.TOGGLE_VIEW, view }),
  editEmpresa: empresa => ({ type: actions.UPDATE_EDIT_EMPRESA, empresa }),
  getEmpresasByUserId: id => ({ type: actions.GET_EMPRESA_BY_USER_ID, id }),
};
export default actions;
