export function getView(width) {
  let newView = 'MobileView';
  if (width > 1220) {
    newView = 'DesktopView';
  } else if (width > 767) {
    newView = 'TabView';
  }
  return newView;
}
const actions = {
  COLLPSE_CHANGE: 'COLLPSE_CHANGE',
  COLLPSE_OPEN_DRAWER: 'COLLPSE_OPEN_DRAWER',
  CHANGE_OPEN_KEYS: 'CHANGE_OPEN_KEYS',
  TOGGLE_ALL: 'TOGGLE_ALL',
  CHANGE_CURRENT: 'CHANGE_CURRENT',
  CLEAR_MENU: 'CLEAR_MENU',
  CREATE_CODIGOS: 'CREATE_CODIGOS',
  CHECK_CODIGO: 'CHECK_CODIGO',
  CODIGO_VERIFICADO: 'CODIGO_VERIFICADO',
  ENVIAR_CODIGOS: 'ENVIAR_CODIGOS',
  GET_CODIGOS: 'GET_CODIGOS',
  UPDATE_CODIGO: 'UPDATE_CODIGO',
  UPDATE_CODIGOS: 'UPDATE_CODIGOS',
  DELETE_CODIGOS: 'DELETE_CODIGOS',
  UPDATE_CATEGORIAS: 'UPDATE_CATEGORIAS',
  UPDATE_CATEGORIA: 'UPDATE_CATEGORIA',
  DELETE_CATEGORIAS: 'DELETE_CATEGORIAS',
  CREATE_CATEGORIAS: 'CREATE_CATEGORIAS',
  GET_CATEGORIAS: 'GET_CATEGORIAS',
  GET_INFO_BASE: 'GET_INFO_BASE',
  UPDATE_INFO_BASE: 'UPDATE_INFO_BASE',
  GET_USER_INFO_DASHBOARD: 'GET_USER_INFO',
  ENVIAR_CODIGOS_CARTA: 'ENVIAR_CODIGOS_CARTA',
  SEND_EMAIL: 'SEND_EMAIL',
  getInfoBase: () => {
    return {
      type: actions.GET_INFO_BASE,
    }
  },
  getUserInfo: () => {
    return {
      type: actions.GET_USER_INFO_DASHBOARD,
    }
  },
  createCategorias: ( categoria ) => {
    return {
      type: actions.CREATE_CATEGORIAS,
      categoria,
    }
  },
  enviarCodigos: ( data ) => {
    return {
      type: actions.ENVIAR_CODIGOS,
      data,
    }
  },
  updateCatCartaMenu: ( data ) => {
    return {
      type: actions.ENVIAR_CODIGOS_CARTA,
      data,
    }
  },
  chkCodes: ( codeChk ) =>{
    return {
      type: actions.CHECK_CODIGO,
      codeChk
    }
  },
  getCategorias: () => {
    return {
      type: actions.GET_CATEGORIAS,
    }
  },
  deleteCategorias: ( id ) => {
    return {
      type: actions.DELETE_CATEGORIAS,
      id
    }
  },
  updateCategorias: ( categoria ) => {
    return {
      type: actions.UPDATE_CATEGORIA,
      categoria,
    }
  },
  createCodigos: ( transaccion ) => {
    return {
      type: actions.CREATE_CODIGOS,
      transaccion,
    }
  },
  getCodigos: () => {
    return {
      type: actions.GET_CODIGOS,
    }
  },
  updateCodigo: ( id, data ) => {
    return {
      type: actions.UPDATE_CODIGO,
      id,
      data,
    }
  },
  deleteCodigos: ( id ) => {
    return {
      type: actions.DELETE_CODIGOS,
      id
    }
  },
  toggleCollapsed: () => ({
    type: actions.COLLPSE_CHANGE,
  }),
  toggleAll: (width, height) => {
    const view = getView(width);
    const collapsed = view !== 'DesktopView';
    return {
      type: actions.TOGGLE_ALL,
      collapsed,
      view,
      height,
    };
  },
  toggleOpenDrawer: () => ({
    type: actions.COLLPSE_OPEN_DRAWER,
  }),
  changeOpenKeys: openKeys => ({
    type: actions.CHANGE_OPEN_KEYS,
    openKeys,
  }),
  changeCurrent: current => ({
    type: actions.CHANGE_CURRENT,
    current,
  }),
  clearMenu: () => ({ type: actions.CLEAR_MENU }),
  sendEmail: ( payload ) => {
    return {
      type: actions.SEND_EMAIL,
      payload,
    }
  },
};
export default actions;
