import actions from './actions';

const initialState = {
    pedidos: [],
    currentPedido: null,
};

export default function pedidoReducer(state = initialState, action) {
    switch (action.type) {
        case actions.GET_PEDIDOS_SUCCESS:
            return {
                ...state,
                pedidos: action.payload,
            }
        case actions.CREATE_PEDIDO_SUCCESS:
            return {
                ...state,
                pedidos: [...state.pedidos, action.payload]
            }
        case actions.EDIT_PEDIDO_SUCCESS:
            return {
                ...state,
                pedidos: state.pedidos.map(pedido => pedido._id === action.payload._id ? action.payload : pedido)
            }
        case actions.DELETE_PEDIDO_SUCCESS:
            return {
                ...state,
                pedidos: state.pedidos.filter(pedido => pedido._id !== action.payload)
            }
        case actions.SET_CURRENT_PEDIDO:
            return {
                ...state,
                currentPedido: action.payload
            }
        case actions.CLEAR_CURRENT_PEDIDO:
            return {
                ...state,
                currentPedido: null
            }
        default:
            return state;
    }

}
