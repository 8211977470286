import profileActions from './actions';

const INITIAL_DATA = {
  data: null,
  loading: true,
  error: null,
  empresas:[],
  currentEmpresa: {},
  currentProfile: {},
};
export default function profileReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case profileActions.FETCH_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case profileActions.FETCH_PROFILE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case profileActions.SET_PROFILE_DATA:
      return {
        ...state,
        loading: false,
        data: action.profile,
      };
    case profileActions.PROFILE_EMPRESAS:
      return {
        ...state,
        empresas:action.empresas,
      }
    case profileActions.FETCH_CURRENT_EMPRESA_SUCCESS:
      return {
        ...state,
        currentEmpresa:action.payload,
      }
    case profileActions.GET_CURRENT_PROFILE_SUCCESS:
      return {
        ...state,
        currentProfile: action.payload,
      }
    default:
      return state;
  }
}
