// saga.js
import { all, takeEvery, put } from "redux-saga/effects";
import profileActions from "./actions";
import { notification } from "@iso/components";
import authActions from "../auth/actions";
const gaxios = require("gaxios");

function* fetchProfileDataEffect() {
  try {
    const res = yield gaxios.request({
      url: "api/users/" + localStorage.getItem("id_token"),
      headers: {
        Authorization: localStorage.getItem("access_token"),
        AuthId: localStorage.getItem("id_token"),
      },
    });

    yield put(profileActions.fetchProfileDataSuccess(res.data));
  } catch (error) {
    console.log(error);
    yield put(profileActions.fetchProfileDataFailure(error));
  }
}

function* updateProfile(action) {
  try {
    const res = yield gaxios.request({
      url: `https://e5c885q80e.execute-api.us-east-2.amazonaws.com/pruebas/perfil/${action.id}`,
      method: "PUT",
      data: action.payload,
    });

    if (!res.data) {
      notification("error", "Error al actualizar el perfil");
    } else {
      notification("success", "Perfil actualizado correctamente");
      yield put({
        type: profileActions.GET_CURRENT_PROFILE_REQUEST,
        payload: action.id,
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getCurrentProfile(action) {
  try {
    const res = yield gaxios.request({
      url: `https://e5c885q80e.execute-api.us-east-2.amazonaws.com/pruebas/perfil/${action.payload}`,
    });

    if (!res.data) {
      notification("error", "Error al obtener el perfil");
    } else {
      yield put({
        type: profileActions.GET_CURRENT_PROFILE_SUCCESS,
        payload: res.data,
      });

      yield put({
        type: authActions.UPDATE_LOGIN_INFO,
        profile: res.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* fetchCurrentEmpresa() {
  try {
    const res = yield fetch(
      "https://e5c885q80e.execute-api.us-east-2.amazonaws.com/pruebas/perfil",
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
      }
    );
    const json = yield res.json();
    yield put(profileActions.fetchCurrentEmpresaSuccess(json.data));
  } catch (error) {
    console.log(error);
  }
}

function* actualizarPerfil(action) {
  try {
    const res = yield gaxios.request({
      url: "api/users/" + action.payload._id,
      method: "PUT",
      data: action.payload,
      headers: {
        Authorization: localStorage.getItem("access_token"),
        AuthId: localStorage.getItem("id_token"),
      },
    });
    if (res.data.success) {
      notification("success", res.data.msg);
      yield put({
        type: profileActions.SET_PROFILE_DATA,
        profile: res.data.user,
      });
      yield put({
        type: authActions.UPDATE_LOGIN_INFO,
        profile: res.data.user,
      });
    }
  } catch (error) {
    yield put(profileActions.fetchProfileDataFailure(error));
  }
}

export default function* profileSaga() {
  yield all([
    takeEvery(profileActions.FETCH_PROFILE_DATA_START, fetchProfileDataEffect),
    takeEvery(profileActions.FETCH_PROFILE_DATA_UPDATE, actualizarPerfil),
    takeEvery(
      profileActions.FETCH_CURRENT_EMPRESA_REQUEST,
      fetchCurrentEmpresa
    ),
    takeEvery(profileActions.GET_CURRENT_PROFILE_REQUEST, getCurrentProfile),
    takeEvery(profileActions.UPDATE_PROFILE_REQUEST, updateProfile),
  ]);
}
