import actions from './actions';

const initState = {
  denuncias: [],
  currentDenuncia:null,
  loading: false,
  error: false,
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_DENUNCIAS:
    case actions.GET_DENUNCIA:
    case actions.DELETE_DENUNCIA:
    case actions.EDIT_DENUNCIA:
      return {
        ...state,
        loading: true,
      };
    case actions.FORMAT_DENUNCIAS:
      return {
        ...state,
        loading: false,
        error: false,
        denuncias: action.result,
      };
    case actions.GIT_ERROR_RESULT:
      return {
        ...state,
        loading: false,
        error: true,
        denuncias: [],
      };
    default:
      return state;
  }
}
