import { all, takeEvery, put, select } from 'redux-saga/effects';
import actions from './actions';
import { notification } from '@iso/components';
import profileActions from "@iso/redux/profile/actions";
const gaxios = require('gaxios');


export function* getUsersSaga() {
  try {
    // const { id_token } = yield select(state => state.Auth);
    const res = yield gaxios.request({
      url: 'https://e5c885q80e.execute-api.us-east-2.amazonaws.com/pruebas/usuarios',
      // url: 'https://e5c885q80e.execute-api.us-east-2.amazonaws.com/pruebas/pruebas',
      // headers: {
      //   'Codice-Auth': id_token,
      // }
    });

    if (!res.data) {
      notification('error', 'Error al obtener los usuarios');
    } else {
      yield put({
        type: actions.ADD_USERS,
        users: res.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* importUsers(action) {
  let ULRDestino = "api/upload/importUsers/";
  const res = yield gaxios.request({
    url: ULRDestino,
    method: "POST",
    data: action.data,
  });
  if (res.data.success) {
    notification('success', res.data.msg)
    yield put({
      type: actions.GET_USERS,
    });
  } else {
    console.log(res)
  }
}

export function* editUser(action) {
  try {
    const res = yield gaxios.request({
      url: `https://e5c885q80e.execute-api.us-east-2.amazonaws.com/pruebas/usuarios/${action.id}`,
      method: "PUT",
      data: action.payload,
    });

    if (!res.data) {
      notification("error", "Error al actualizar el perfil");
    } else {
      notification("success", "Perfil actualizado correctamente");
      const { id } = yield select(state => state.Auth);
      if (action.id === id) {
        yield put({
          type: profileActions.GET_CURRENT_PROFILE_REQUEST,
          payload: action.id,
        });
      }
      yield put({
        type: actions.GET_USERS,
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* deleteUser(action) {
  const res = yield gaxios.request({
    url: `https://e5c885q80e.execute-api.us-east-2.amazonaws.com/pruebas/usuarios/${action.id}`,
    method: 'DELETE',
  });

  if (res.status !== 200) {
    notification('error', 'Sin Informacion');
  } else {
    notification('success', 'Usuario eliminado correctamente');
    yield put({
      type: actions.GET_USERS,
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.GET_USERS, getUsersSaga),
    yield takeEvery(actions.EDIT_USER, editUser),
    yield takeEvery(actions.DELETE_USER, deleteUser),
    yield takeEvery(actions.IMPORT_USERS, importUsers),
  ]);
}
