const profileActions = {
  FETCH_PROFILE_DATA_START: 'FETCH_PROFILE_DATA_START',
  FETCH_PROFILE_DATA_UPDATE: 'FETCH_PROFILE_DATA_UPDATE',
  FETCH_PROFILE_DATA_SUCCESS: 'FETCH_PROFILE_DATA_SUCCESS',
  FETCH_PROFILE_DATA_FAILURE: 'FETCH_PROFILE_DATA_FAILURE',
  PROFILE_EMPRESAS: 'PROFILE_EMPRESAS',
  SET_PROFILE_DATA: 'SET_PROFILE_DATA',
  FETCH_CURRENT_EMPRESA_REQUEST: 'FETCH_CURRENT_EMPRESA_REQUEST',
  FETCH_CURRENT_EMPRESA_SUCCESS: 'FETCH_CURRENT_EMPRESA_SUCCESS',
  GET_CURRENT_PROFILE_REQUEST: 'GET_CURRENT_PROFILE_REQUEST',
  GET_CURRENT_PROFILE_SUCCESS: 'GET_CURRENT_PROFILE_SUCCESS',
  UPDATE_PROFILE_REQUEST: 'UPDATE_PROFILE_REQUEST',
  UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
  fetchProfileDataStart: () => ({
    type: profileActions.FETCH_PROFILE_DATA_START,
  }),
  fetchProfileDataSuccess: profile => ({
    type: profileActions.FETCH_PROFILE_DATA_SUCCESS,
    payload: profile,
  }),
  fetchProfileDataUpdate: profile => ({
    type: profileActions.FETCH_PROFILE_DATA_UPDATE,
    payload: profile,
  }),
  fetchProfileDataFailure: error => ({
    type: profileActions.FETCH_PROFILE_DATA_FAILURE,
    payload: error,
  }),
  fetchCurrentEmpresaRequest: () => ({
    type: profileActions.FETCH_CURRENT_EMPRESA_REQUEST,
  }),
  fetchCurrentEmpresaSuccess: empresa => ({
    type: profileActions.FETCH_CURRENT_EMPRESA_SUCCESS,
    payload: empresa,
  }),
  getCurrentProfile: (id) => ({
    type: profileActions.GET_CURRENT_PROFILE_REQUEST,
    payload: id,
  }),
  updateProfile: (id, data) => ({
    type: profileActions.UPDATE_PROFILE_REQUEST,
    id,
    payload: data,
  }),
};

export default profileActions;
