import { all, takeEvery, put, select } from 'redux-saga/effects';
import { notification } from '@iso/components';
import actions from './actions';
const gaxios = require('gaxios');


function* denuncias(action) {
  const query = action.query ? `?${action.query}` : '';
  const res = yield gaxios.request({
    url: 'https://e5c885q80e.execute-api.us-east-2.amazonaws.com/pruebas/denuncias' + query,
    method: 'GET',
  });
  if (res.status !== 200) {
    notification('error', 'Error al obtener las denuncias');
  } else {
    yield put({
      type: actions.FORMAT_DENUNCIAS,
      result: res.data,
    });
  }
}

function* tramitar(action) {
  const { id } = yield select(state => state.Auth);
  action.data.tramitador = id;
  const res = yield gaxios.request({
    url: 'https://e5c885q80e.execute-api.us-east-2.amazonaws.com/pruebas/denuncias/' + action.data.denuncia,
    method: 'PUT',
    data: action.data
  });
  if (res.status !== 200) {
    notification('success', 'Error al traminar la denuncia');
  } else {
    yield put({
      type: actions.GET_DENUNCIAS,
    });
  }
}
export default function* rootSaga() {
  yield all([takeEvery(actions.GET_DENUNCIAS, denuncias)]);
  yield all([takeEvery(actions.EDIT_DENUNCIA, tramitar)]);
}
