const actions = {
    GET_PRODUCTOS_REQUEST: 'GET_PRODUCTOS_REQUEST',
    GET_PRODUCTOS_SUCCESS: 'GET_PRODUCTOS_SUCCESS',
    GET_PRODUCTOS_BY_ID_REQUEST: 'GET_PRODUCTOS_BY_ID_REQUEST',
    GET_PRODUCTOS_BY_ID_SUCCESS: 'GET_PRODUCTOS_BY_ID_SUCCESS',

    CREATE_PRODUCTO_REQUEST: 'CREATE_PRODUCTO_REQUEST',
    CREATE_PRODUCTO_SUCCESS: 'CREATE_PRODUCTO_SUCCESS',

    EDIT_PRODUCTO_REQUEST: 'EDIT_PRODUCTO_REQUEST',
    EDIT_PRODUCTO_SUCCESS: 'EDIT_PRODUCTO_SUCCESS',

    DELETE_PRODUCTO_REQUEST: 'DELETE_PRODUCTO_REQUEST',
    DELETE_PRODUCTO_SUCCESS: 'DELETE_PRODUCTO_SUCCESS',

    SET_CURRENT_PRODUCTO: 'SET_CURRENT_PRODUCTO',
    CLEAR_CURRENT_PRODUCTO: 'CLEAR_CURRENT_PRODUCTO',
    
    initDataProductos: (query) => ({ type: actions.GET_PRODUCTOS_REQUEST, query }),
    getProductosById: (id) => ({ type: actions.GET_PRODUCTOS_BY_ID_REQUEST, id }),
    createProducto: data => ({
      type: actions.CREATE_PRODUCTO_REQUEST,
      payload: data
    }),
    editProducto: (id, data) => ({
      type: actions.EDIT_PRODUCTO_REQUEST,
      id,
      payload: data
    }),
    deleteProducto: id => ({
      type: actions.DELETE_PRODUCTO_REQUEST,
      payload: id
    }),
    setCurrentProducto: data => ({
      type: actions.SET_CURRENT_PRODUCTO,
      payload: data
    }),
    clearCurrentProducto: () => ({
      type: actions.CLEAR_CURRENT_PRODUCTO,
    }), 
  };
  export default actions;
  