import actions from './actions';

const initState = { id_token: null, access_token: null, cdt: 0, recoveryVerify:false, email: '', id: '', nombre: '' };

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        access_token: action.access_token,
        id_token: action.id_token,
        cdt: action.cdt,
        nombre:action.nombre,
        email:action.email,
        avatar: action.avatar,
        id: action.id
      };
    case actions.UPDATE_LOGIN_INFO:
      return{
        ...state,
        nombre: action.profile.nombre ? action.profile.nombre : '',
        email:action.profile.email,
        cdt: action.profile.permisos ? action.profile.permisos : 0,
      };
    case actions.LOGOUT_SUCCESS:
      return initState;
    default:
      return state;
  }
}
