import { all } from 'redux-saga/effects';
import authSagas from '@iso/redux/auth/saga';
import usersSagas from '@iso/redux/users/saga';
import mailSagas from '@iso/redux/mail/saga';
import denunciasSagas from '@iso/redux/denuncias/sagas';
import profileSaga from '@iso/redux/profile/saga';
import empresasSagas from '@iso/redux/empresas/saga';
import appSagas from '@iso/redux/app/saga';
import productosSagas from '@iso/redux/productos/saga';
import configSagas from '@iso/redux/generalConfig/saga';
import migracionSagas from '@iso/redux/migracion/saga';
import pedidosSagas from '@iso/redux/pedidos/saga';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    usersSagas(),
    mailSagas(),
    denunciasSagas(),
    profileSaga(),
    empresasSagas(),
    appSagas(),
    productosSagas(),
    configSagas(),
    migracionSagas(),
    pedidosSagas()
  ]);
}
