import actions from './actions';

const initialState = {
    empresasMigradas: [],
    empresasPaginadas: []
};

export default function productoReducer(state = initialState, action) {
    switch (action.type) {
        case actions.GET_EMPRESAS_SUCCESS:
            return {
                ...state,
                empresasMigradas: action.payload
            }
        case actions.GET_EMPRESAS_PAGINADAS_SUCCESS:
            return {
                ...state,
                empresasPaginadas: action.payload
            }
        default:
            return state;
    }

}
